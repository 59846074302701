import React, { Component, useState } from 'react';
import { string, func, bool, object } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { APPLY_COUPON_ALL_ITEMS, CUSTOMCOUPON, DOLLAROFF, PERCENTOFF, USER_ROLE_MERCHANT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, Button, Modal, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import vintageCopper from '../../assets/LULO-Tagline-VintageCopper.png';
import moment from 'moment';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const { Money, UUID } = sdkTypes;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);

    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};
const realPriceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);

    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    addToCart,
    profileListing,
    marketplaceCurrency,
    onManageDisableScrolling,
    serviceListing,
    phoneNumber,
  } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);

  const isMerchantListing =
    currentListing?.attributes?.publicData?.listingType === USER_ROLE_MERCHANT;
  const { title = '', price, publicData } = currentListing.attributes;
  const {
    numberOfCoupon,
    merchantBusinessName,
    couponCode,
    dateOfExpire,
    couponDescription,
    couponPercentageOff,
    couponDollerSpend,
    couponTypes,
    couponDollerOff,
    availableItem,
    specificListing,
    barCodeImage,
    vendorLocation,
    customCoupon,
    values
  } = publicData || '';

  const offerDetails = publicData?.offerDetails == "applies-only-sales-Merchant"
    ? "Redeemable at merchant's location only"
    : publicData?.offerDetails == "Applies to LuLocal Marketplace Sales Only"
    ? "Applies to LuLocal Marketplace Sales Only"
    : publicData?.offerDetails == "Applies to LuLocal sales or coupon can be presented at the merchant’s business location"
    ? "Applies to LuLocal sales or coupon can be presented at the merchant’s business location"
    : null;
    
  const locationParts = vendorLocation && vendorLocation.split(',').map(part => part.trim());
  const lastTwoParts = locationParts && locationParts.slice(-2);
  const LocationResult = lastTwoParts && lastTwoParts.join(', ');

  const allSpecificItems =
    specificListing && specificListing.map(items => items.label).join(',' + ' ');

  const { listingType } = publicData || '';
  const productListing = listing?.attributes?.publicData?.category == 'Products';

  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author?.attributes?.profile?.displayName;
  const id = isMerchantListing ? author?.id?.uuid : currentListing?.id?.uuid;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const isListingType = listing?.attributes?.publicData?.listingType == USER_ROLE_MERCHANT;
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith('default'))
    : [];
  const offeredprice = price ? price?.amount / 100 : null;
  const percentageOff =
    publicData &&
    publicData.percentageOff &&
    typeof publicData.percentageOff == 'string' &&
    publicData.percentageOff;

  const calculateDiscountedPrice = (price, percentageOff) => {
    if (!price) {
      throw new Error(`Missing DiscountedPrice in ${title}`);
    }

    if (percentageOff < 0 || percentageOff > 100) {
      return new Money(price * 100, marketplaceCurrency);
    }
    const discount = (price * percentageOff) / 100;
    const discountedPrice = price - discount;

    return new Money(discountedPrice * 100, marketplaceCurrency);
  };

  const discountedPrice =
    offeredprice && percentageOff
      ? calculateDiscountedPrice(offeredprice, parseInt(percentageOff))
      : false;
  const { formattedPrice, priceTitle } =
    percentageOff && discountedPrice
      ? priceData(discountedPrice, config.currency, intl)
      : priceData(price, config.currency, intl);

  const amount = price?.amount;
  const dollarSpendFormat = parseInt(couponDollerSpend);

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;
 
  return (
    <div className={classes}>
      {listingType == 'COUPON' && author && author?.id && (
        <>
          <AspectRatioWrapper
            className={classNames(css.aspectRatioWrapper, profileListing && css.couponImage)}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />
          </AspectRatioWrapper>
          <div className={css.businessName}>
            {merchantBusinessName ? merchantBusinessName : null}
          </div>

          {!addToCart ? (
            <div className={css.info}>
              <div className={css.price}>
                <div className={css.priceValue} title={priceTitle}>
                  {(() => {
                    switch (couponTypes) {
                      case PERCENTOFF:
                        return `${amount / 100}% OFF`;
                      case DOLLAROFF:
                        return `Spend $${dollarSpendFormat}, Get $${amount / 100} Off`;
                      case CUSTOMCOUPON:
                        return customCoupon;
                      default:
                        return !serviceListing && formattedPrice;
                    }
                  })()}
                </div>
                <div className={css.seeCoupon}>
                  <Button
                    className={css.submitButton}
                    type="button"
                    onClick={() => {
                      setIsOpenModal(true);
                    }}
                  >
                    See Coupon
                  </Button>
                </div>

                {isBookingProcessAlias(publicData?.transactionProcessAlias) ? (
                  <div className={css.perUnit}>
                    <FormattedMessage
                      id="ListingCard.perUnit"
                      values={{ unitType: publicData?.unitType }}
                    />
                  </div>
                ) : null}
              </div>
              <div className={css.mainInfo}></div>
            </div>
          ) : (
            <div className={css.info}>
              <div className={css.mainInfo}>
                <div className={css.title}>
                  {richText(title, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                    longWordClass: css.longWord,
                  })}
                </div>
                <div className={css.title}>-</div>
                {showAuthorInfo ? (
                  <div className={css.authorInfo}>
                    <FormattedMessage id="ListingCard.author" values={{ authorName }} />
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </>
      )}
      {listingType !== 'COUPON' && author && author?.id && (
        <NamedLink
          className={classNames(classes, addToCart && css.addToCartWrapper)}
          name={isMerchantListing ? 'ProfilePage' : 'ListingPage'}
          params={{ id, slug }}
        >
          <AspectRatioWrapper
            className={css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />
          </AspectRatioWrapper>
          <div className={css.mainInfo}>
            <div className={css.authorInfo}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
          </div>
          {!addToCart ? (
            <div className={css.info}>
              <div className={css.listingPrice}>
                <div className={css.listingPriceValue} title={priceTitle}>
                  {productListing && formattedPrice ? (
                    <>
                      {formattedPrice}
                      <span className={css.offPrice}>
                        {percentageOff ? (
                          <>
                            <span className={css.lineThrough}>
                              {price && formatMoney(intl, price)}
                            </span>
                            <span>{percentageOff} off</span>
                          </>
                        ) : null}
                      </span>
                    </>
                  ) : null}
                </div>
                {/* {isListingType ?
                  <div>
                    {numberOfCoupon && numberOfCoupon.length != 0 ? numberOfCoupon + "coupons" : null}
                  </div>
                  : null} */}
                {isBookingProcessAlias(publicData?.transactionProcessAlias) ? (
                  <div className={css.perUnit}>
                    <FormattedMessage
                      id="ListingCard.perUnit"
                      values={{ unitType: publicData?.unitType }}
                    />
                  </div>
                ) : null}
              </div>
              <div className={css.mainInfo}></div>
            </div>
          ) : (
            <div className={css.info}>
              <div className={css.mainInfo}>
                <div className={css.title}>-</div>
                {showAuthorInfo ? (
                  <div className={css.authorInfo}>
                    <FormattedMessage id="ListingCard.author" values={{ authorName }} />
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </NamedLink>
      )}
      <div>
        <Modal
          id="VerifyDeletingPaymentMethod"
          isOpen={isOpenModal}
          onClose={() => {
            setIsOpenModal(false);
          }}
          onManageDisableScrolling={() => {}}
          className={css.paymentModal}
        >
          <div className={css.mainWrapperModal}>
            <div className={css.paymentLeft}>
              <div className={css.topbarModal}>
                <div className={css.offPercent}>
                  {couponTypes == DOLLAROFF? (
                    <span className={css.spendDollar}>
                      <span><FormattedMessage id="ReviewCoupon.spend"/> ${couponDollerSpend ? couponDollerSpend : null}</span>
                      {","}{" "}
                      <span><FormattedMessage id="ReviewCoupon.get"/> ${couponDollerOff ? couponDollerOff : null}</span>
                      <span>
                      <FormattedMessage id="ReviewCoupon.offYourPurchase"/>
                      {/* of{' '}
                        {availableItem == 'apply coupon to all items'
                          ? ' any products'
                          : allSpecificItems} */}
                        
                      </span>
                    </span>
                  ) : couponTypes == CUSTOMCOUPON ?
                   <span>{values?.customCoupon}</span>      
                     :(
                    <span className={css.spendDollar}>
                      <span>{couponPercentageOff}% Off of</span>
                      <span>
                        {availableItem == APPLY_COUPON_ALL_ITEMS
                          ? <FormattedMessage id="ListingCard.anypurchase"/>
                          : allSpecificItems}
                        
                      </span>
                    </span>
                  )}
                </div>
                {barCodeImage && (
                  <div className={css.qrCode}>
                    <img src={barCodeImage} />
                  </div>
                )}
              </div>
              <div className={css.bodyModalRow}>
                <div className={css.bodyLeft}>
                  <AspectRatioWrapper
                    className={css.aspectRatioWrapper}
                    width={aspectWidth}
                    height={aspectHeight}
                    {...setActivePropsMaybe}
                  >
                    <LazyImage
                      rootClassName={css.rootForImage}
                      alt={title}
                      image={firstImage}
                      variants={variants}
                      sizes={renderSizes}
                    />
                  </AspectRatioWrapper>
                  <div className={css.authorAddress}>{LocationResult}</div>
                </div>
                <div className={css.bodyRight}>
                  <ul>
                    <li>
                      <span className={css.mainTitle}><FormattedMessage id="ReviewCoupon.businessName" /></span>
                      <span className={css.detailData}>
                        {merchantBusinessName ? merchantBusinessName : null}
                      </span>
                    </li>
                    <li>
                      <span className={css.mainTitle}> <FormattedMessage id="ReviewCoupon.couponCode" /></span>
                      <span className={css.detailData}>{couponCode ? couponCode : null}</span>
                    </li>
                    <li>
                      <span className={css.mainTitle}> <FormattedMessage id="ReviewCoupon.offerExpires" /></span>
                      <span className={css.detailData}>
                        {dateOfExpire ? moment(dateOfExpire).format('MM-DD-YYYY') : null}
                      </span>
                    </li>
                    <li>
                      <span className={css.mainTitle}><FormattedMessage id="ReviewCoupon.redeem" /></span>
                      <span className={css.detailData}>{offerDetails ? offerDetails : null}</span>
                    </li>
                  </ul>
                  <div className={css.offerRow}>
                    <div className={css.offerDetail}>
                      <span className={css.upperText}>
                      <FormattedMessage id="ReviewCoupon.offerDetails" /> 
                        <span className={css.normalText}>
                          {couponDescription ? couponDescription : null}
                        </span>
                      </span>
                    </div>
                    <div className={css.offerImage}>
                      <img src={vintageCopper} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={css.paymentRight} />
          </div>
        </Modal>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
