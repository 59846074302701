/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */

export const subCatergoriesOptions = [
  {
    option: 'Jewelry/Accessories',
    value: 'Jewelry/Accessories',
    label: 'Jewelry/Accessories',
    parentId: 'Products',
  },
  { option: 'Clothing', value: 'Clothing', label: 'Clothing', parentId: 'Products' },
  { option: 'Footwear', value: 'Footwear', label: 'Footwear', parentId: 'Products' },
  { option: 'Home/Garden', value: 'Home/Garden', label: 'Home/Garden', parentId: 'Products' },
  {
    option: 'Health/Beauty Supply',
    value: 'Health/Beauty Supply',
    label: 'Health/Beauty Supply',
    parentId: 'Products',
  },
  { option: 'Auto', value: 'Auto', label: 'Auto', parentId: 'Products' },
  { option: 'Pet Supplies', value: 'Pet Supplies', label: 'Pet Supplies', parentId: 'Products' },
  {
    option: 'Books/Games/Music',
    value: 'Books/Games/Music',
    label: 'Books/Games/Music',
    parentId: 'Products',
  },
  {
    option: 'Grocery/Pharmacy',
    value: 'Grocery/Pharmacy',
    label: 'Grocery/Pharmacy',
    parentId: 'Products',
  },
  {
    option: 'Sporting Goods',
    value: 'Sporting Goods',
    label: 'Sporting Goods',
    parentId: 'Products',
  },
  { option: 'Children', value: 'Children', label: 'Children', parentId: 'Products' },
  {
    option: 'Electronics/Phone',
    value: 'Electronics/Phone',
    label: 'Electronics/Phone',
    parentId: 'Products',
  },
  {
    option: 'Hardware/Patio/Garden',
    value: 'Hardware/Patio/Garden',
    label: 'Hardware/Patio/Garden',
    parentId: 'Products',
  },
  {
    option: 'Local Artisans',
    value: 'Local Artisans',
    label: 'Local Artisans',
    parentId: 'Products',
  },
  { option: 'Home Repair', value: 'Home Repair', label: 'Home Repair', parentId: 'Services' },
  { option: 'Home Services', value: 'Home Services', label: 'Home Services', parentId: 'Services' },
  { option: 'Lesson', value: 'Lesson', label: 'Lesson', parentId: 'Services' },
  { option: 'Pet Services', value: ' Pet Services', label: ' Services', parentId: 'Services' },
  {
    option: 'Lawn and Garden',
    value: 'Lawn and Garden',
    label: 'Lawn and Garden',
    parentId: 'Services',
  },
  { option: 'Real Estate', value: 'Real Estate', label: 'Real Estate', parentId: 'Services' },
  {
    option: 'Personal Services',
    value: 'Personal Services',
    label: 'Personal Services',
    parentId: 'Services',
  },
  { option: 'Auto', value: 'AutA', label: 'Auto', parentId: 'Services' },
  {
    option: 'Professional services',
    value: 'Professional services',
    label: 'Professional services',
    parentId: 'Services',
  },
  { option: 'Beauty', value: 'Beauty', label: 'Beauty', parentId: 'Services' },
  { option: 'Youth Service', value: 'Youth Service', label: 'Youth Service', parentId: 'Services' },
  { option: 'Education', value: 'Education', label: 'Education', parentId: 'Services' },
  {
    option: 'Health & fitness',
    value: 'Health & fitness',
    label: 'Health & fitness',
    parentId: 'Services',
  },
  { option: 'Medical', value: 'Medical', label: 'Medical', parentId: 'Services' },
  {
    option: 'Electronics/Phone',
    value: 'Electronics/Phone',
    label: 'Electronics/Phone',
    parentId: 'Services',
  },
  { option: 'Event Space', value: 'Event Space', label: 'Event Space', parentId: 'Services' },
  { option: 'Rentals', value: 'Rentals', label: 'Rentals', parentId: 'Services' },
  { option: 'Catering', value: 'Catering', label: 'Catering', parentId: 'Services' },
  {
    option: 'Entertainment & Arts',
    value: 'Entertainment & Arts',
    label: 'Entertainment & Arts',
    parentId: 'Services',
  },
  { option: 'Restaurants', value: 'Restaurants', label: 'Restaurants', parentId: 'Food & Drinks' },
  {
    option: 'Adult Beverages',
    value: 'Adult Beverages',
    label: 'Adult Beverages',
    parentId: 'Food & Drinks',
  },
  {
    option: 'Coffee & Tea',
    value: 'Coffee & Tea',
    label: 'Coffee & Tea',
    parentId: 'Food & Drinks',
  },
];

export const haveStoreHome = [
  { option: 'Store', label: 'Store' },
  { option: 'Home-based', label: 'Home-based' },
];

export const directbuy = [
  { option: 'yes', label: 'Yes' },
  { option: 'no', label: 'No' },
];

export const productListingType = [
  { key: 'sell-with-online', label: 'Sell with Online Payments' },
  {
    key: 'marketing-only',
    label: 'Marketing Only: No online payments will be collected through LuLocal',
  },
];

export const userProduct = [
  { key: 'Products', label: 'Products' },
  { key: 'Services', label: 'Services' },
  { key: 'Food-&-drink', label: 'Food & Drink' },
];

export const userProductSubcategory = [
  {
    key: 'Jewelry/Accessories',
    label: 'Jewelry/Accessories',
    parentId: 'product',
  },
  {
    key: 'Clothing',
    label: 'Clothing',
    parentId: 'product',
  },
  {
    key: 'Footwear',
    label: 'Footwear',
    parentId: 'product',
  },
  {
    key: 'Health/Beauty ',
    label: 'Health/Beauty ',
    parentId: 'product',
  },
  {
    key: 'Pet Supplies',
    label: 'Pet Supplies',
    parentId: 'product',
  },
  {
    key: 'Home/Décor',
    label: 'Home/Décor',
    parentId: 'product',
  },
  {
    key: 'Books/Music/Instruments',
    label: 'Books/Music/Instruments',
    parentId: 'product',
  },
  {
    key: 'Games/Toys/Hobbies',
    label: 'Games/Toys/Hobbies',
    parentId: 'product',
  },
  {
    key: 'Grocery/Pharmacy',
    label: 'Grocery/Pharmacy',
    parentId: 'product',
  },
  {
    key: 'Sporting Goods',
    label: 'Sporting Goods',
    parentId: 'product',
  },
  {
    key: 'Hardware/Garden',
    label: 'Hardware/Garden',
    parentId: 'product',
  },
  {
    key: 'Electronics/Phones',
    label: 'Electronics/Phones',
    parentId: 'product',
  },
  {
    key: 'Local Artisans',
    label: 'Local Artisans',
    parentId: 'product',
  },
  {
    key: 'Auto',
    label: 'Auto',
    parentId: 'product',
  },
  {
    key: 'Home Services',
    label: 'Home Services',
    parentId: 'service',
  },
  {
    key: 'Personal Services',
    label: 'Personal Services',
    parentId: 'service',
  },
  {
    key: 'Pet Services',
    label: 'Pet Services',
    parentId: 'service',
  },
  {
    key: 'Professional Services',
    label: 'Professional Services',
    parentId: 'service',
  },
  {
    key: 'Medical/Dental',
    label: 'Medical/Dental',
    parentId: 'service',
  },
  {
    key: 'Lessons/Tutoring',
    label: 'Lessons/Tutoring',
    parentId: 'service',
  },
  {
    key: 'Beauty',
    label: 'Beauty',
    parentId: 'service',
  },
  {
    key: 'Fitness/Sports',
    label: 'Fitness/Sports',
    parentId: 'service',
  },
  {
    key: 'Wellness/Nutrition',
    label: 'Wellness/Nutrition',
    parentId: 'service',
  },
  {
    key: 'Electronics/Phone',
    label: 'Electronics/Phone',
    parentId: 'service',
  },
  {
    key: 'Rentals',
    label: 'Rentals',
    parentId: 'service',
  },
  {
    key: 'Catering/Events',
    label: 'Catering/Events',
    parentId: 'service',
  },
  {
    key: 'Entertainment & Arts',
    label: 'Entertainment & Arts',
    parentId: 'service',
  },
  {
    key: 'Auto',
    label: 'Auto',
    parentId: 'service',
  },
  {
    key: 'Dining',
    label: 'Dining',
    parentId: 'food-&-drink',
  },
  {
    key: 'Bakery/Treats',
    label: 'Bakery/Treats',
    parentId: 'food-&-drink',
  },
  {
    key: 'Beverages',
    label: 'Beverages',
    parentId: 'food-&-drink',
  },
];

// export const offerDetails = [
//   {
//     key: 'Applies to LuLocal Marketplace Sales Only',
//     value: 'Applies to LuLocal Marketplace Sales Only',
//   },
//   {
//     key: 'Applies to LuLocal sales or coupon can be presented at the merchant’s business location',
//     value:
//       'Applies to LuLocal sales or coupon can be presented at the merchant’s business location',
//   },
//   {
//     key:
//       'Applies only to sales completed directly with the Merchant. Does not apply to LuLocal purchases',
//     value:
//       'Applies only to sales completed directly with the Merchant. Does not apply to LuLocal purchases',
//   },
// ];

// export const getDollarOff = [
//   { key: '1', value: '$1' },
//   { key: '3', value: '$3' },
//   { key: '5', value: '$5' },
//   { key: '10', value: '$10' },
//   { key: '15', value: '$15' },
//   { key: '20', value: '$20' },
//   { key: '25', value: '$25' },
//   { key: '30', value: '$30' },
//   { key: '40', value: '$40' },
//   { key: '50', value: '$50' },
//   { key: '75', value: '$75' },
//   { key: '100', value: '$100' },
// ];
// export const couponType = [
//   { key: 'percent-off', value: ' Percent Off Purchase' },
//   { key: 'dollars-off', value: 'Spend X, Get Y Dollars Off' },
//   { key: "create-custom-coupon", value: "Create Custom Offer (Custom offers are only redeemable directly with the merchant)" },
// ];
// export const percentageOff = [
//   { key: '5', value: '5%' },
//   { key: '10', value: '10%' },
//   { key: '15', value: '15%' },
//   { key: '20', value: '20%' },
//   { key: '25', value: '25%' },
//   { key: '30', value: '30%' },
//   { key: '35', value: '35%' },
//   { key: '40', value: '40%' },
//   { key: '45', value: '45%' },
//   { key: '50', value: '50%' },
// ];

// export const onSpendDollars = [
//   { key: '20', value: '$20' },
//   { key: '30', value: '$30' },
//   { key: '40', value: '$40' },
//   { key: '50', value: '$50' },
//   { key: '75', value: '$75' },
//   { key: '100', value: '$100' },
//   { key: '150', value: '$150' },
//   { key: '200', value: '$200' },
//   { key: '350', value: '$350' },
//   { key: '500', value: '$500' },
//   { key: '1000', value: '$1000' },
// ];

// export const standardTemplates = [
//   { key: 'standardTemplate1', value: 'Standard Template 1' },
//   { key: 'standardTemplate2', value: 'Standard Template 2' },
//   { key: 'standardTemplate3', value: 'Standard Template 3' },
// ];

// export const availableItem = [
//   { key: 'apply coupon to specific item', value: 'Apply Coupon to Specific Item' },
//   { key: 'apply coupon to all items', value: 'Apply Coupon to all items' },
// ];


export const listingfilter =[ 
  {
    key: 'category',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'shop-by-merchants', label: 'Merchants', value: 'Merchant' },
      { option: 'Products', label: 'Products', value: 'Products' },
      { option: 'Services', label: 'Services', value: 'Services' },
      { option: 'shop-by-food&drink', label:'Food & Drink', value:'food-&-drink'},
      { option: 'Coupon', label: 'Coupons', value: 'Coupon' }
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Category',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'listingSubCategory',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: subCatergoriesOptions,
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Sub Category',
      group: 'secondary',
    },
    showConfig: {
      label: 'Sub Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Sub Category',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a sub category.',
    },
  },
  {
    key: 'subCategories',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: subCatergoriesOptions,
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Sub Category',
      group: 'secondary',
    },
    showConfig: {
      label: 'Sub Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Sub Category',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a sub category.',
    },
  },

];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  // {
  //   listingType: 'daily-booking',
  //   label: 'Daily booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'day',
  //   },
  // },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // 
  {
    listingType: 'product-selling',
    label: 'Sell bicycles',
    transactionType: {
      process: 'default-purchase',
      alias: 'default-purchase/release-1',
      unitType: 'item',
    },
    stockType: 'multipleItems',
  },
];

export const couponType = [
  { key: 'percent-off', value: ' Percent Off Purchase' },
  { key: 'dollars-off', value: 'Spend X, Get Y Dollars Off' },
  { key: "create-custom-coupon", value: "Create Custom Offer (Custom offers are only redeemable directly with the merchant)" },
];

export const couponTheme = [
  { key: 'standard', value: 'View Standard Theme options' },
  { key: 'seasonal', value: 'View Seasonal Theme options' },
];

export const seasonalTemplate = [
  { key: 'winter', value: 'Winter' },
  { key: 'summer', value: 'Summer' },
  { key: 'spring', value: 'Spring' },
  { key: 'autumn', value: 'Autumn' },
];

export const standardTemplates = [
  { key: 'standardTemplate1', value: 'Standard Template 1' },
  { key: 'standardTemplate2', value: 'Standard Template 2' },
  { key: 'standardTemplate3', value: 'Standard Template 3' },
];

export const percentageOff = [
  { key: '5', value: '5%' },
  { key: '10', value: '10%' },
  { key: '15', value: '15%' },
  { key: '20', value: '20%' },
  { key: '25', value: '25%' },
  { key: '30', value: '30%' },
  { key: '35', value: '35%' },
  { key: '40', value: '40%' },
  { key: '45', value: '45%' },
  { key: '50', value: '50%' },
];

export const produtSize = [
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
  { key: '5', label: '5' },
  { key: '6', label: '6' },
  { key: '7', label: '7' },
  { key: '8', label: '8' },
  { key: '9', label: '9' },
  { key: '10', label: '10' },
];

export const getDollarOff = [
  { key: '1', value: '$1' },
  { key: '3', value: '$3' },
  { key: '5', value: '$5' },
  { key: '10', value: '$10' },
  { key: '15', value: '$15' },
  { key: '20', value: '$20' },
  { key: '25', value: '$25' },
  { key: '30', value: '$30' },
  { key: '40', value: '$40' },
  { key: '50', value: '$50' },
  { key: '75', value: '$75' },
  { key: '100', value: '$100' },
];

export const onSpendDollars = [
  { key: '20', value: '$20' },
  { key: '30', value: '$30' },
  { key: '40', value: '$40' },
  { key: '50', value: '$50' },
  { key: '75', value: '$75' },
  { key: '100', value: '$100' },
  { key: '150', value: '$150' },
  { key: '200', value: '$200' },
  { key: '350', value: '$350' },
  { key: '500', value: '$500' },
  { key: '1000', value: '$1000' },
];

export const availableItem = [
  { key: 'apply-specific-coupon', value: 'Apply Coupon to Specific Item' },
  { key: 'apply-all-coupon', value: 'Apply Coupon to all items' },
];

export const offerDetails = [
  {
    key: 'Applies to LuLocal Marketplace Sales Only',
    value: 'Applies to LuLocal Marketplace Sales Only',
  },
  {
    key: 'Applies to LuLocal sales or coupon can be presented at the merchant’s business location',
    value:
      'Applies to LuLocal sales or coupon can be presented at the merchant’s business location',
  },
  {
    key:
      'applies-only-sales-Merchant',
    value:
      "Redeemable at merchant's location only",
  },
];

export const mostUsedColors = [
  { key: 'fixed-price', label: 'Fixed Price' },
  { key: 'variable-price', label: 'Variable Price' },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
