import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm } from 'react-final-form';

import * as validators from '../../../util/validators';
import { directbuy } from '../../../config/configListing';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldRadioButton,
  H5,
  NamedLink,
  FieldLocationAutocompleteInput,
  CustomExtendedDataField,
} from '../../../components';

import logo from '../../../assets/LULO-Primary-VintageCopper-229x80.png';
import { getPropsForCustomUserFieldInputs } from '../../../util/userHelpers';

import css from './SignupForm.module.css';
const identity = v => v;
const getSoleUserTypeMaybe = userTypes => {
  Array.isArray(userTypes) && userTypes.length === 1 ? userTypes[0].userType : null;
};

const SignupFormComponent = props => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize={true}
      initialValues={{
        userType: props.preselectedUserType || getSoleUserTypeMaybe(props.userTypes),
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          signupInProgress,
          invalid,
          intl,
          termsAndConditions,
          values,
          pageName,
          errorMessage,
          userTypes,
          userFields,
          CustomerSignUpPage,
        } = fieldRenderProps;
        const { userType } = values || {};
        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        //confirm password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        // password
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );
        const matchPassword =
          values.password !== undefined && values.confirmpassword !== undefined ? (
            values.password === values.confirmpassword ? (
              ''
            ) : (
              <span className={css.passwordWarnHeading}>Password is not matched</span>
            )
          ) : (
            ''
          );
        const userFieldProps = getPropsForCustomUserFieldInputs(userFields, intl, userType);

        const noUserTypes = !userType && !(userTypes?.length > 0);
        const showCustomUserFields = (userType || noUserTypes) && userFieldProps?.length > 0;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = signupInProgress || inProgress;
        const submitDisabled =
          invalid || submitInProgress || values.password !== values.confirmpassword;
  
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.logoImage}>
              <NamedLink name="LandingPage">
                <img className={css.logoImg} src={logo} alt="" />
              </NamedLink>
            </div>
            <div
              className={classNames(
                css.signUpWrapper,
                pageName == 'MerchantSignUpPage' && css.merchantSignUpWrapper
              )}
            >
              {pageName == 'MerchantSignUpPage' ? (
                <div className={css.leftContent}>
                  <h1>
                    {' '}
                    <FormattedMessage id="ConfirmSignupForm.becomeMerchant" />
                  </h1>
                  <p>
                    <FormattedMessage id="ConfirmSignupForm.signUpDescription" />{' '}
                  </p>
                  <p>
                    {' '}
                    <FormattedMessage id="ConfirmSignupForm.wePromote" />
                  </p>
                </div>
              ) : null}
              <div
                className={classNames(
                  css.rightContent,
                  pageName == 'MerchantSignUpPage' && css.merchantRight
                )}
              >
                {pageName == 'MerchantSignUpPage' || CustomerSignUpPage ? null : (
                  <div className={css.signUpContents}>
                    {/* <img src={logo} alt="" /> */}
                    <h2>
                      {' '}
                      <FormattedMessage id="ConfirmSignupForm.shopperSignUp" />
                    </h2>
                    <p>
                      <FormattedMessage id="ConfirmSignupForm.shopperDesc" />
                    </p>
                  </div>
                )}

                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.NameLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id:
                      pageName == 'MerchantSignUpPage'
                        ? 'SignupForm.firstNameHolder'
                        : 'SignupForm.NameLabel',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                {pageName == 'MerchantSignUpPage' ? (
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    autoComplete="family-name"
                    // label={intl.formatMessage({
                    //   id: 'SignupForm.lastNameLabel',
                    // })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.lastNameHolder',
                    })}
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.lastNameRequired',
                      })
                    )}
                  />
                ) : null}
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.emailLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.emailPlaceHolder',
                  })}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <FieldTextInput
                  className={css.password}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.passwordLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.passwordPlaceholder',
                  })}
                  validate={passwordValidators}
                />
                <FieldTextInput
                  className={css.password}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="confirmpassword"
                  autoComplete="new-password"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.confirmPasswordLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.confirmPasswordLabel',
                  })}
                />
                {matchPassword}

                {pageName == 'MerchantSignUpPage' ? (
                  <>
                    {showCustomUserFields ? (
                      <div className={css.customFields}>
                        {userFieldProps.map(fieldProps => (
                          <CustomExtendedDataField {...fieldProps} formId={formId} />
                        ))}
                      </div>
                    ) : null}
                    {values.HomeStore ? (
                      <>
                        <H5 className={css.descriptionText}>
                          <FormattedMessage id="ConfirmSignupForm.homeStore" />
                        </H5>
                        <FieldLocationAutocompleteInput
                          rootClassName={css.input}
                          inputClassName={css.locationAutocompleteInput}
                          iconClassName={css.locationAutocompleteInputIcon}
                          predictionsClassName={css.predictionsRoot}
                          validClassName={css.validLocation}
                          name="location"
                          // label={intl.formatMessage({ id: 'EditListingDeliveryForm.address' })}
                          placeholder={intl.formatMessage({
                            id: 'EditListingDeliveryForm.addressPlaceholder1',
                          })}
                          useDefaultPredictions={false}
                          format={identity}
                          valueFromForm={values.location}
                        />
                      </>
                    ) : null}

                    <div className={css.businessRadioContents}>
                      <FormattedMessage id="SignupForm.yesno" />

                      <div className={css.businessRadioBtn}>
                        {directbuy.map((st, i) => (
                          <div key={st.label + i}>
                            <FieldRadioButton
                              id={st.option}
                              key={st.option}
                              name={'directbuy'}
                              value={st.option}
                              label={st.label}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <FieldTextInput
                        className={css.password}
                        type="text"
                        id={formId ? `${formId}.promoCode` : 'promoCode'}
                        name="promoCode"
                        label={intl.formatMessage({
                          id: 'SignupForm.promoCodeLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'SignupForm.promoCodeplaceholder',
                        })}
                      />
                    </div>
                  </>
                ) : null}

                {errorMessage}
                <div className={css.bottomWrapper}>
                  {termsAndConditions}
                  <PrimaryButton
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                  >
                    <FormattedMessage id="SignupForm.submit" />
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
