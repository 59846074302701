import React, { useEffect, useState } from 'react';
import css from './ManageSubscriptionPanel.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { useDispatch, useSelector } from 'react-redux';
import { ensureUser } from '../../util/data';
import moment from 'moment';
import Button from '../Button/Button';
import { getSubscriptions } from '../../containers/StripeSubscriptionPage/StripeSubscriptionPage.duck';
import { cancelStripeSubscription } from '../../util/api';
import { SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_CANCELLED, SUBSCRIPTION_STATUS_TRAILING } from '../../util/types';
import { firstLetterCapital } from '../../util/helper';
// import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import verifyIcon from '../../assets/1024x1024-verified.gif';

const ManageSubscriptionPanel = ({ history }) => {
  const routeConfiguration = useRouteConfiguration();
  const [userSubscription, setUserSubscription] = useState([]);
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state?.user?.currentUser);
  const subscriptionId = currentUser?.attributes?.profile?.protectedData?.userSubscriptionData?.id;

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        if (subscriptionId) {
          const subscription = await dispatch(getSubscriptions({ subscriptionId }));
          if (subscription) {
            setUserSubscription(subscription);
          }
        }
      } catch (error) {
        console.error('Error fetching subscription:', error);
      }
    };

    if (currentUser?.id) {
      fetchSubscription();
    }
  }, [dispatch, currentUser?.id]);

  const manageSubscription = async subscriptionId => {
    try {
      const response = await cancelStripeSubscription({ subscriptionId: subscriptionId });

      if (response.status === 'canceled') {
        history.push(
          createResourceLocatorString('StripeSubscriptionPage', routeConfiguration, {}, {})
        );
      }
    } catch (err) {
      console.log(err, '&&& &&& => err');
    }
  };

  if (!userSubscription) {
    return <SkeletonLoader />;
  }

  const { plan, items, status, cancel_at_period_end, cancel_at } = userSubscription;
  const planNickname = plan?.nickname || 'Unknown Plan';
  const planAmount = plan?.amount ? `$${(plan.amount / 100).toFixed(2)}` : 'N/A';
  const subscriptionStatus = cancel_at_period_end
    ? 'Cancelled'
    : firstLetterCapital(status || 'Inactive');
  const subscriptionItems = Array.isArray(userSubscription) ? userSubscription : [userSubscription];
  console.log(userSubscription, '&&& &&& => userSubscription');


  return (
    <div className={css.manageSubscriptionPanel}>
      <div className={css.headingTopBoxs}>
        <div className={css.billingInformationTitle}>
          <FormattedMessage id="ManageSubscriptionPanel.billingInformation" />
        </div>
        <div className={css.verifyBox}>
          <img src={verifyIcon}
            alt="verified"
          />
          <span>Subscription Completed- Welcome to Lulocal!</span>
        </div>
      </div>

      <div className={css.planWrapper}>
        <h3 className={css.nameHeading}>
          <FormattedMessage id="ManageSubscriptionPanel.onlinePlanHeading" />
        </h3>
        <div className={css.planCard}>
          <div className={css.wrapperBox}>
            <div className={css.mobilePlanHeading}>
              <div
                className={
                  cancel_at_period_end
                    ? css.inMobileInActiveBadge
                    : css.mobileActive
                }
              >
                {subscriptionStatus}
              </div>
            </div>
            <div className={css.planHeading}>
              <div className={css.planLeftSide}>
                <div className={css.planHeadingBox}>
                  {<h3> {planNickname}</h3>}
                  <span
                    className={
                      cancel_at_period_end
                        ? css.inActiveBadge
                        : css.activeBadge
                    }
                  >
                    {subscriptionStatus}
                  </span>
                </div>
              </div>
              <div className={css.planRightSide}>
              </div>
            </div>
            <div className={css.teamInformation}></div>
          </div>
          <div className={css.planBody}>
            <div className={css.planBodyRight}>
              <Button
                onClick={() => manageSubscription(subscriptionId)}
                className={css.subscriptionButton}
              // disabled={true}
              >
                <FormattedMessage id="ManageSubscriptionPanel.cancelSubscriptionButton" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <h3 className={css.nameHeading}>
        <FormattedMessage id="ManageSubscriptionPanel.billingHistory" />
      </h3>
      <div className={css.tableBox}>
        <table className={css.tableStriped}>
          <thead>
            <tr className={css.subscriptionHeading}>
              <th className={css.leftTd}>
                <FormattedMessage id="ManageSubscriptionPanel.planHeading" />
              </th>
              <th className={css.centerTd}>
                <FormattedMessage id="ManageSubscriptionPanel.billingDetailsHeading" />
              </th>
              <th className={css.centerTd}>
                <FormattedMessage id="ManageSubscriptionPanel.nextBillingDateHeading" />
              </th>
              <th className={css.rightTd}>
                <FormattedMessage id="ManageSubscriptionPanel.amountHeading" />
              </th>
            </tr>
          </thead>
          <tbody>
            {subscriptionItems.length > 0 ? (
              subscriptionItems.map(st => {
                console.log(st, '&&& &&& => st');
                const isTrailSubscription = st.status == SUBSCRIPTION_STATUS_TRAILING;
                const isActiveSubscription = st.status === SUBSCRIPTION_STATUS_ACTIVE;
                const amount = st?.plan?.amount
                  ? `$${(st?.plan?.amount / 100).toFixed(2)}`
                  : 'N/A';
                const startDate = moment.unix(st.current_period_start).format('MMM D, YYYY');
                console.log(startDate, '&&& &&& => startDate');
                
                const endDate = moment.unix(st.current_period_end ).format('MMM D, YYYY');
                console.log(endDate, '&&& &&& => endDate');
                
                const trailStartDate = moment.unix(st.trial_start).format('MMM D, YYYY');
                const trailEndDate = moment.unix(st.trial_end).format('MMM D, YYYY');
                return (
                  <>
                    {isActiveSubscription ? (
                      <tr key={st.id} className={css.subscriptionData}>
                        <td className={css.leftTd}>{'Lulocal Plan'}</td>
                        <td className={css.centerTd}>{startDate}</td>
                        <td className={css.centerTd}>{endDate}</td>
                        <td className={css.rightTd}>{amount}</td>
                      </tr>
                    ) : isTrailSubscription ? (
                      <tr key={st.id} className={css.subscriptionData}>
                        <td className={css.leftTd}>{'Lulocal Plan (Trial)'}</td>
                        <td className={css.centerTd}>{trailStartDate}</td>
                        <td className={css.centerTd}>{trailEndDate}</td>
                        <td className={css.rightTd}>{amount}</td>
                      </tr>
                    ) : null}
                  </>
                );
              })
            ) : (
              null
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageSubscriptionPanel;
