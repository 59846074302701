import React, { useState } from 'react';

import css from './MerchantFAQ.module.css';
import { Footer, LayoutSingleColumn, NamedLink, Page, Topbar } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Collapsible from 'react-collapsible';
import faqImg from '../../assets/faq.png';
import IconCollection from '../../components/IconCollection/IconCollection';
import { FormattedMessage } from 'react-intl';
import faqImage from '../../../src/assets/merchantfaq.jpeg';
import { Button } from '@material-ui/core';
const MerchantFAQPage = props => {
  const { scrollingDisabled } = props;
  const topbar = <TopbarContainer />;
  const [activeTab, setActiveTab] = useState(1);

  return (
    <Page scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={topbar} footer={<Footer />}>
        <div className={css.faqWrapper}>
          <div className={css.titleSection}>
            <div className={css.titleWrapper}>
              {/* <h1><FormattedMessage id="FaqPage.faqTitle"/></h1>
            <h6><FormattedMessage id="FaqPage.byShopper"/></h6> */}
              <h1>Merchant FAQs</h1>
              <h6>You’ve got questions —</h6>
              <h6>We’ve got answers</h6>
            </div>
            <div className={css.imageSection}>
              <img src={faqImage} />
            </div>
          </div>

          <div className={css.tabWrapper}>
            <div>
              <div className={css.topic}>Topic:</div>
              <a
                className={activeTab == 1 && css.activeTab}
                onClick={() => setActiveTab(1)}
                href="#generalQuestion"
              >
                General Questions
              </a>
              <a
                className={activeTab == 2 && css.activeTab}
                onClick={() => setActiveTab(1)}
                href="#selling"
              >
                Selling
              </a>
              <a
                className={activeTab == 3 && css.activeTab}
                onClick={() => setActiveTab(1)}
                href="#payments"
              >
                Payments & Refunds
              </a>
              <a
                className={activeTab == 4 && css.activeTab}
                onClick={() => setActiveTab(3)}
                href="#Inventory"
              >
                Inventory & Shipping
              </a>
              <a
                className={activeTab == 5 && css.activeTab}
                onClick={() => setActiveTab(3)}
                href="#Marketing"
              >
                Marketing
              </a>
              <a
                className={activeTab == 6 && css.activeTab}
                onClick={() => setActiveTab(1)}
                href="#Coupons"
              >
                Coupons
              </a>
              <a
                className={activeTab == 7 && css.activeTab}
                onClick={() => setActiveTab(2)}
                href="#Support"
              >
                Support FAQs
              </a>
            </div>
          </div>
          <div id="generalQuestion" className={css.myAccountWrapper}>
            <h3>General Questions</h3>
            <Collapsible
              trigger={
                <>
                  <h4>What does the name LuLocal stand for?</h4>
                </>
              }
            >
              <p>LuLocal is short for “Love You Local!”</p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>
                    Does LuLocal take a percentage of the sales revenue that is sold through the
                    marketplace?
                  </h4>
                </>
              }
            >
              <p>
                No. Whether you complete a sale directly in our marketplace or in your physical
                store, you keep 100% of the sale, less standard merchant service fees. Stripe, our
                merchant service provider, charges 2.9% + .30 cents for each transaction in the
                marketplace. LuLocal does not receive any of these funds.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>I didn’t receive my Merchant registration email. Now what?</h4>
                </>
              }
            >
              <p>
                You should have received it within minutes of signing up. Most likely, your email
                security automatically directed your approval email to your junk mail folder. If you
                don’t see it there, please email us at support@lulocal.com and we will check into it
                for you.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Do I need to be tech savvy to set up and manage my LuLocal Account?</h4>
                </>
              }
            >
              <p>
                Definitely not. We have a self-guided process that asks you questions, and you just
                provide the answers or select from one of our drop-down options. Easy Peasy! If you
                get stuck, email us at support@lulocal.com – we are happy to help.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>
                    I want to highlight listings on Lulocal, but I only want shoppers to buy
                    directly from me. Is that possible?
                  </h4>
                </>
              }
            >
              <p>
                Absolutely. Transacting through LuLocal’s marketplace is an option available to
                merchants, but not a requirement. When you create your product or service listing,
                select from one of our three preset purchase options available to shoppers. Purchase
                Directly with the Merchant, LuLocal Only or Both. LuLocal promotes your business and
                offerings, you decide where the transactions take place.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>How long does it take to setup my store?</h4>
                </>
              }
            >
              <p>
                Initial set-up takes about 45 minutes. Once done, it takes about 10 minutes to post
                a listing or create a coupon.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>
                    Once I set up a merchant account, will I also be able be able to browse and buy
                    from other merchants?
                  </h4>
                </>
              }
            >
              <p>
                Yes! Merchants are automatically set up with a Shopper account during the Merchant
                sign-up process.
              </p>
            </Collapsible>
          </div>
          <div id="selling" className={css.myAccountWrapper}>
            <h3>Selling</h3>
            <Collapsible
              trigger={
                <>
                  <h4>What information is presented on my dedicated business page?</h4>
                </>
              }
            >
              <p>
                Your business page has a section for each of the following: business description,
                business photos, current announcements, contact Info, your website address, and a
                map of your location. You also have a section for current coupons (using LuLocal’s
                coupon tool) and up to nine product or service listings with photos to promote and
                sell.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>How many products and services can I promote at any one time?</h4>
                </>
              }
            >
              <p>
                You can promote up to nine at one time. You can always keep the same listings up, or
                you can swap new ones as often as you like to keep things fresh.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>I have a home-based business, can I sell on LuLocal?</h4>
                </>
              }
            >
              <p>
                Absolutely! LuLocal is ideal for home-based businesses looking for a cost-effective
                way to reach their local audience. When you set-up your merchant account, select the
                “home-based” business option. This way, the map will not show your home location,
                just the township to which you are located.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Do I need to have my own business website to sell with LuLocal?</h4>
                </>
              }
            >
              <p>
                No - You are not required to have a website. Simply set-up a merchant account and
                list up to nine products or services at a time. You list, we promote!
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>After I sign up, will I be charged any additional marketing fees?</h4>
                </>
              }
            >
              <p>
                You will not be changed any fees other than your selected monthly plan. LuLocal uses
                most of the funds it collects from merchant monthly fees to drive traffic to
                LuLocal.com. This way, merchants share shopping and marketing funds to keep your
                acquisition costs at a minimum.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>
                    Am I required to add coupons or reduce the cost of my products or services?
                  </h4>
                </>
              }
            >
              <p>
                Definitely not. We have promotion tools built-in, but it’s up to the merchant to use
                them or not.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>
                    I don’t have set prices for the type of services I sell. Can I still create
                    listings and promote my services on LuLocal without a stated price?
                  </h4>
                </>
              }
            >
              <p>
                You bet! Simply create a service listing and provide a title along with a
                description of the services you provide. You can direct shoppers to your place of
                business or have them contact you via email, phone, etc. to set up a meeting or
                provide a quote.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>I want to make an announcement on my business page. Where do I start?</h4>
                </>
              }
            >
              <p>
                It takes less than a minute to create or edit business announcements. Login to your
                account, click Profile Settings, then select Announcement. Once done, type in your
                announcement and you’re good to go. This is the ideal place to announce in-store
                sales, celebrate your business anniversary, announce new products or services, etc.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>
                    I have a home-based business and don’t want my home address to be shown on the
                    map located on my business page. Can that be done?
                  </h4>
                </>
              }
            >
              <p>
                No problem. When you set up your merchant account, you have an option to select
                “home-based” business. One selected, you will be directed to keep your address blank
                and only include your city and state. The map will only show the city to which you
                are located.
              </p>
            </Collapsible>
          </div>
          <div id="payments" className={css.myAccountWrapper}>
            <h3>Payments & Refunds</h3>
            <Collapsible
              trigger={
                <>
                  <h4>
                    Who is the merchant of record for sales completed through the LuLocal
                    marketplace?
                  </h4>
                </>
              }
            >
              <p>
                The merchant of record is the seller and funds are sent from Stripe, our merchant
                service provider to the merchant’s Stripe account. If the merchant doesn’t already
                have a Stripe account, they will be setup with an account during the sign-up
                process. Creating a Stripe account takes less than 10 minutes. During registration,
                LuLocal will re-direct the merchant to Stripe’s registration page to sign-up. Once
                done, the merchant will be redirected back to LuLocal’s registration page to
                complete the process.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>
                    Who provides the merchant services for LuLocal’s credit card transactions?
                  </h4>
                </>
              }
            >
              <p>
                Stripe is our merchant service provider, and we use their marketplace product called
                Stripe Connect. Essentially, Stripe processes the payment and collects the merchant
                service fees (2.9% + 30 cents per transaction) and sends the rest of the funds
                directly to the merchant’s Stripe account. To complete transaction on LuLocal's
                platform, merchants are required to set-up a Stripe Account or use their existing
                Stripe account if one is already in place. It takes less than 10 minutes to set up a
                Stripe account. This process is handled directly by Stripe and the merchant will be
                re-directed back to LuLocal’s sign up process upon completion.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>When will I be charged for my LuLocal plan?</h4>
                </>
              }
            >
              <p>
                Merchants will be auto-charged every 30 days from the day of the initial payment.
                All plans are month to month and can be cancelled at any time. Once cancelled,
                accounts will remain active until the pre-paid 30-day period has expired.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>How are customer refunds handled?</h4>
                </>
              }
            >
              <p>
                LuLocal creates a marketing connection between merchants and shoppers in the
                marketplace, but does not collect sales revenue and does not handle customer
                returns. Sales and refund transactions occur directly between the seller and the
                merchant.
              </p>
            </Collapsible>
          </div>
          <div id="Inventory" className={css.myAccountWrapper}>
            <h3>Inventory & Shipping</h3>
            <Collapsible
              trigger={
                <>
                  <h4>
                    Is the merchant responsible for processing and shipping products sold in the
                    LuLocal marketplace?
                  </h4>
                </>
              }
            >
              <p>
                LuLocal does not store or ship products. It is up to the merchants to decide if they
                want to provide shipping and if so, the merchant is responsible for the processing
                and shipment of their orders. Merchants can also offer in-store pickup instead of
                shipping.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Can I connect my inventory system to LuLocal?</h4>
                </>
              }
            >
              <p>Not at this time, but this is a feature we will be consider as we grow.</p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Does the merchant set their own shipping charges for their products?</h4>
                </>
              }
            >
              <p>
                Yes, merchants decide the price for shipping. Every product listing has a field for
                the delivery charge. It can be free shipping or any price you want.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>
                    What delivery or pick-up options are available for items purchased through the
                    LuLocal marketplace?
                  </h4>
                </>
              }
            >
              <p>
                Two dropdown options are available for merchants to offer shoppers at the time of
                purchase: In-store pick-up or shipping. Merchants can offer both options to shoppers
                or just one of the options. Merchants decide which method they prefer for each of
                their product listings. For example, if a merchant has two listings, they may have
                In-store pick up for one item and shipping for the other. The merchant may also
                offer both options and the customers decide which option they want during check-out.
              </p>
            </Collapsible>
          </div>
          <div id="Marketing" className={css.myAccountWrapper}>
            <h3>Marketing</h3>
            <Collapsible
              trigger={
                <>
                  <h4>How does LuLocal drive traffic to the marketplace?</h4>
                </>
              }
            >
              <p>
                LuLocal leverages marketing partnerships and uses monthly fees collected from
                merchants to advertise and drive traffic to the marketplace. This gives
                participating merchants the ability to share marketing dollars and shoppers with the
                community, keeping costs lower than it would be as a stand-alone advertiser.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>How can I make my business stand-out in the marketplace?</h4>
                </>
              }
            >
              <p>
                Our goal is to keep LuLocal fresh and interesting for shoppers. For that reason, we
                stack rank merchants based on recency of coupons. If you want to be the first
                merchant shown, simply create a coupon and you will be in the #1 slot. When another
                merchant creates a coupon, you slide to #2 and so on.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Do you offer paid advertising space in the marketplace?</h4>
                </>
              }
            >
              <p>
                Not at this time. Our goal is to keep LuLocal fresh and interesting for shoppers.
                For that reason, we stack rank merchants based on recency of coupons. If you want to
                be the first merchant shown, simply create a coupon and you will be in the #1 slot
                on the merchant page. When another merchant creates a coupon, you slide to #2 and so
                on.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>
                    Can I share the link to my LuLocal business page in other places I market?
                  </h4>
                </>
              }
            >
              <p>
                Absolutely! Think of Lulocal as your promotion center and post your link everywhere
                you market today. Share your link on your website and social media so shoppers can
                stay informed of your announcements, promotions, and highlighted offers. LuLocal
                makes it easy to manage your promotions and coupons!
              </p>
            </Collapsible>
          </div>
          <div id="Coupons" className={css.myAccountWrapper}>
            <h3>Coupons</h3>
            <Collapsible
              trigger={
                <>
                  <h4>How do I design a coupon for my store?</h4>
                </>
              }
            >
              <p>
                Click your profile logo on the top right of your merchant account page. Select
                “Create Coupon” and enter in the requested information. It only takes a few minutes
                to create a coupon. It will be presented to shoppers within 24 hours of submission,
                and it will disappear automatically on your expiration date. You can also set a
                future start date and your coupon will appear and disappear automatically on the
                dates you assigned.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Can I create my coupon in advance? What happens when my coupon expires?</h4>
                </>
              }
            >
              <p>
                Yes! You can create your coupon in advance, and it will automatically be presented
                to shoppers on your listed start date. When it expires, your coupon will
                automatically disappear from your merchant page and the coupon page. In short, once
                you create your coupon, LuLocal manages it from there – no additional work is
                required on your end.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Can I limit coupon redemption to In-Store only?</h4>
                </>
              }
            >
              <p>
                Yes! When the coupon is created, you select where you want it to be redeemed.
                Redemption options include the LuLocal marketplace, the merchant’s physical location
                or both locations. The shopper is presented with redemption options selected by the
                merchant.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Can I track the number of coupons that are redeemed?</h4>
                </>
              }
            >
              <p>
                Yes, you can track the number of coupons codes that have been redeemed in the
                LuLocal marketplace. LuLocal is unable to track the coupons redeemed directly with
                your business. You can however add your own QR code when you create your coupon to
                track in-store coupon redemption.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Can I set a limit to the number of coupons that can be used?</h4>
                </>
              }
            >
              <p>
                Not at this time. Coupons are managed by a start and end date only, but you do have
                the ability to delete a coupon at any time. Example: If your coupons expire in 10
                days, but your stock has become limited, you can simply delete the coupon from your
                merchant account, and it will stop showing to new shoppers.
              </p>
            </Collapsible>
          </div>
          <div id="Support" className={css.myAccountWrapper}>
            <h3>My Account</h3>
            <Collapsible
              trigger={
                <>
                  <h4>I’m having trouble setting up my account. Now what?</h4>
                </>
              }
            >
              <p>
                If you have difficulty creating coupons or listings, please send us an email at
                support@lulocal.com and we will be happy to help!
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>I forgot my username/password for my account. How do I log in?</h4>
                </>
              }
            >
              <p>
                No worries! Go to LuLocal’s home page and click “Login/Sign Up.” At the bottom of
                page click “Sign In.” In the center of the following page, click “Reset Password”.
                You will be prompted to enter the email address you used to sign up. Once entered,
                an email will be sent that includes instructions to set-up a new password. If you
                don’t receive the email within a few minutes, check you junk file.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>How do I delete my LuLocal merchant account?</h4>
                </>
              }
            >
              <p>
                While we would be sorry to see you go, you can simply cancel your account to stop
                payments or email us at support@lulocal.com and request a cancellation. Your account
                will remain active through the end of your prepaid 30-day period. We are unable to
                issue partial credits for mid-month cancellations.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Can I put my account on hold while I am away from my business?</h4>
                </>
              }
            >
              <p>
                We are unable to put your account on hold, but you can simply delete your
                highlighted products or service offering in your account. We recommend that you add
                text in the “Announcement” section of your merchant page so shoppers can see when
                you will be back. Perfect for seasonal businesses that want to continue to promote
                their business during the off-season. This will help you continue to build your
                brand awareness and keep shoppers informed while you are away.
              </p>
            </Collapsible>
            <div className={css.supportLink}>
              If you have any other questions, please email us at{' '}
              <NamedLink
              name="LandingPage"
              >
                support@lulocal.com
              </NamedLink>
            </div>
          </div>
          {/* <div className={css.localShoppingWrapper}>
            <div className={css.localShopping}>
              <img src={faqImg} />
              <div className={css.localShoppingContent}>
                 <h2> <FormattedMessage id="FaqPage.notShopping"/></h2>
                 <button><FormattedMessage id="FaqPage.howWorks"/></button>
              </div>
            </div>
            <div className={css.rightContent}>
              <div className={css.curiousAbout}>
                <h1>
                <FormattedMessage id="FaqPage.curiousAbout"/> <br /> becoming a merchant?
                </h1>
                <p><FormattedMessage id="FaqPage.exploreYou"/></p>
                <a>Sign Up</a>
              </div>
              <div className={css.stillGot}>
                <h1><FormattedMessage id="FaqPage.stillQuestion"/></h1>
                <p><FormattedMessage id="FaqPage.hereHelp"/></p>
                <NamedLink className={css.createListingLink} name="ContactUsPage">
                <FormattedMessage id="FaqPage.contactUs"/>
                </NamedLink>
              </div>
            </div>
          </div> */}
          <div className={css.localShoppingWrapper}>
            <div className={css.planLink}>
            <a  href='/p/rate-plans'>See Current Rate Plan</a>
            </div>
            <h1>Didn’t find the answer you need?</h1>
            <a href="/contact-us" className={css.link}>
              <Button>Contact Us</Button>
            </a>
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};
const MerchantFAQ = compose(connect(mapStateToProps))(MerchantFAQPage);

export default MerchantFAQ;
